import React from 'react';
import './App.css';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/pages/Home';
import Navbar from './components/Navbar';
import Footer from './components/Footer';

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path='/' exact element={<Home/>} />
        {/* <Route path='/about-us' element = {<Aboutus/> }/> */}
        
      </Routes>
      <Footer/>
    </Router>
  );
}

export default App;