import React, { useState, useEffect } from 'react';


import './Home.css'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import { AccessAlarm, ThreeDRotation } from '@mui/icons-material';
import BuildIcon from '@mui/icons-material/Build';
import Advertise from '../../images/Advertise.jpg'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import FAQ from './faq';
import Description from '../../editableFiles/Description';








const Home = () => {

    const [inputText, setInputText] = useState('');
    const [charCount, setCharCount] = useState(0);
    const [paragraphCount, setParagraphCount] = useState(0);
    const [sentenceCount, setSentenceCount] = useState(0);
    const [wordCount, setWordCount] = useState(0);
    const [wordDensity, setWordDensity] = useState([]);
    const [readinglevel, setReadinglevel] = useState('N/A');
    const [readingTime, setReadingTime] = useState(0);
    const [speakingTme, setSpeakingTime] = useState(0);

    const [textHistory, setTextHistory] = useState([]);
    const [historyPointer, setHistoryPointer] = useState(-1);

    // const textAreaRef = useRef();



    const updateCounts = () => {
        const charLength = inputText.length;
        const paragraphLength = inputText.split('\n').filter((p) => p.trim() !== '').length;
        const sentenceLength = inputText.split(/[.!?]/).filter((s) => s.trim() !== '').length;
        const wordLength = inputText.split(/\s+/).filter((w) => w.trim() !== '').length;


        setCharCount(charLength);
        setParagraphCount(paragraphLength);
        setSentenceCount(sentenceLength);
        setWordCount(wordLength);
    };

    const updateWordDensity = () => {
        const words = inputText.split(/\s+/).filter((w) => w.trim() !== '');
        const totalWords = words.length;
        const densityMap = {};

        words.forEach((word) => {
            densityMap[word] = (densityMap[word] || 0) + 1;
        });

        const densityList = Object.entries(densityMap).map(([word, count]) => ({
            word,
            count,
            percentage: (count / totalWords) * 100,
        }));

        setWordDensity(densityList);
    };


    const calculateReadingLevel = (readingTimeInSeconds) => {
        // Define thresholds for reading levels (adjust as needed)
        const beginnerThreshold = 30; // seconds
        const intermediateThreshold = 60; // seconds
        if (readingTimeInSeconds < 10) {
            return "N/A";
        }
        else if (readingTimeInSeconds <= beginnerThreshold) {
            return "Beginner";
        } else if (readingTimeInSeconds <= intermediateThreshold) {
            return "Intermediate";
        } else {
            return "Fluent";
        }
    };

    const calculateReadingTime = (wordCount) => {
        // Average reading speed in words per minute (adjust as needed)
        const wordsPerMinute = 275;

        // Calculate reading time in minutes
        const readingTimeInMinutes = wordCount / wordsPerMinute;

        // Round up to the nearest whole number
        const roundedReadingTime = Math.ceil(readingTimeInMinutes * 60);

        return roundedReadingTime;
    };

    const calculateSpeakingTime = (wordCount) => {
        // Average speaking speed in words per minute (adjust as needed)
        const wordsPerMinute = 180;

        // Calculate speaking time in minutes
        const speakingTimeInMinutes = wordCount / wordsPerMinute;

        // Round up to the nearest whole number
        const roundedSpeakingTime = Math.ceil(speakingTimeInMinutes * 60);

        return roundedSpeakingTime;
    };

    useEffect(() => {
        // Update counts whenever inputText changes
        updateCounts();
        updateWordDensity();

        //   // Update reading stats using reading-time library
        //   const stats = readingTime(inputText);
        //   setReadingStats(stats);
        const totalWordCount = wordCount;
        const calculatedReadingTime = calculateReadingTime(totalWordCount);
        const calculatedSpeakingTime = calculateSpeakingTime(totalWordCount);
        const readingLevel = calculateReadingLevel(calculatedReadingTime);

        setReadingTime(calculatedReadingTime);
        setSpeakingTime(calculatedSpeakingTime);
        setReadinglevel(readingLevel);
    }, [inputText, wordCount]);

    const handleTextChange = (e) => {
        setInputText(e.target.value);
        const newText = e.target.value;
        setInputText(newText);

        // When the user types, we clear the future history from the current position
        const newHistory = textHistory.slice(0, historyPointer + 1);
        setTextHistory([...newHistory, newText]);
        setHistoryPointer(newHistory.length);
    };

    const handleDelete = () => {
        setInputText('');
    };

    const handleUndo = () => {
        if (historyPointer > 0) {
            const newPointer = historyPointer - 1;
            setInputText(textHistory[newPointer]);
            setHistoryPointer(newPointer);
        }
    };

    const handleRedo = () => {
        if (historyPointer < textHistory.length - 1) {
            const newPointer = historyPointer + 1;
            setInputText(textHistory[newPointer]);
            setHistoryPointer(newPointer);
        }
    };

    return (
        <>
            <div className='home'>
                {/* <Grid container spacing={3}>
                    <Grid item xs>
                        <div>left</div>

                    </Grid>
                    <Grid item xs={8} className='mainGrid'> */}

                <div className='midHome'>
                    <div className='midRight'>
                        <div className='headResult'>{wordCount} words {charCount} characters</div>
                        <div className='textOperation'>
                            {/* <div className='textOperationLeft'>Try This New Era Word Counter</div> */}
                            {/* <div className='textOperationRight'> */}
                            <Link to='/' > <DeleteSweepIcon style={{ color: 'black' }} className='texteditorIcons' onClick={handleDelete} /></Link>
                            <Link to='/' > <UndoIcon style={{ color: 'black' }} className='texteditorIcons' onClick={handleUndo} /></Link>
                            <Link to='/' > <RedoIcon style={{ color: 'black' }} className='texteditorIcons' onClick={handleRedo} /></Link>

                            {/* </div> */}

                        </div>
                        <textarea className='textinput'

                            placeholder="Type your text here..."
                            value={inputText}
                            onChange={handleTextChange}
                        />
                        <Description />

                        <div className='faqSection'>
                            <h3>Frequently asked questions</h3>
                            <FAQ />
                        </div>

                    </div>
                    <div className='midLeft'>
                        {/* <div className='advertisediv'> */}
                        {/* <img
                            src={Advertise} // Replace with the actual URL of your image
                            alt=""
                            className='advertisediv'
                        // style={{ width: '100%', height: 'auto' }}
                        /> */}
                        {/* </div> */}
                        <Accordion defaultExpanded
                            sx={{ backgroundColor: '#f5f5f5', border: '1px solid #ddd', borderRadius: '5px' }}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"

                            >
                                <Typography className='accordianHeader' sx={{ paddingTop: 0 }}><BuildIcon className="mirrored-icon" fontSize='small' /> Details</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className='accordionAtrributes'>
                                    <text className='numbertext'>Characters</text>
                                    <div className='numberCircle' style={{ color: 'White' }}>{charCount}</div>
                                </div>
                                <div className='accordionAtrributes'>
                                    <text className='numbertext'>Words</text>
                                    <div className='numberCircle' style={{ color: 'White' }}>{wordCount}</div>
                                </div>
                                <div className='accordionAtrributes'>
                                    <text className='numbertext'>Paragraphs</text>
                                    <div className='numberCircle' style={{ color: 'White' }}>{paragraphCount}</div>
                                </div>
                                <div className='accordionAtrributes'>
                                    <text className='numbertext'>Sentences</text>
                                    <div className='numberCircle' style={{ color: 'White' }}>{sentenceCount}</div>
                                </div>
                                <div className='accordionAtrributes'>
                                    <text className='numbertext'>Reading Level</text>
                                    <div className='numberCircle' style={{ color: 'White' }}>{readinglevel} </div>
                                </div>
                                <div className='accordionAtrributes'>
                                    <text className='numbertext'>Reading Time</text>
                                    <div className='numberCircle' style={{ color: 'White' }}>{readingTime ? readingTime + ' sec' : '0'} </div>
                                </div>
                                <div className='accordionAtrributes'>
                                    <text className='numbertext'>Speaking Time</text>
                                    <div className='numberCircle' style={{ color: 'White' }}>{speakingTme ? speakingTme + ' sec' : '0'}</div>
                                </div>
                                {/* <div className='accordionAtrributes'>
                                            <text className='numbertext'>Hi</text>
                                            <div className='numberCircle' style={{ color: 'White' }}>{charCount}</div>
                                        </div> */}
                            </AccordionDetails>
                        </Accordion>
                        {/* 2 */}
                        <Accordion defaultExpanded sx={{ backgroundColor: '#f5f5f5', border: '1px solid #ddd', borderRadius: '5px' }}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"

                            >
                                <Typography className='accordianHeader' sx={{ paddingTop: 0 }}><BuildIcon className="mirrored-icon" fontSize='small' />  Keyword Density </Typography>
                            </AccordionSummary>
                            <AccordionDetails>

                                {/* <div > */}
                                {
                                    wordCount ? wordDensity.map((entry) => (
                                        <div key={entry.word} className='accordionAtrributes'>
                                            <div className='numbertext-container'>
                                                <div className={entry.word.length>20 ? 'numbertext scrollanimation' : 'numbertext'}>{entry.word}</div>
                                            </div>
                                            <div className='numberCircle' style={{ color: 'White' }}>{entry.count} ({entry.percentage ? entry.percentage.toFixed(0) + '%' : 'N/A'})</div>

                                        </div>
                                    )) : <div className='defaultwordaccordian'>Start typing your document to get a list of most used keywords</div>
                                }
                                {/* {} */}
                                {/* </div> */}

                            </AccordionDetails>
                        </Accordion>
                        {/* A3 */}
                        {/* <Accordion defaultExpanded sx={{ backgroundColor: '#f5f5f5', border: '1px solid #ddd', borderRadius: '5px' }}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"

                            >
                                <Typography className='accordianHeader' sx={{ paddingTop: 0 }}><BuildIcon className="mirrored-icon" fontSize='small' />  Activity </Typography>
                            </AccordionSummary>
                            <AccordionDetails>

                                

                            </AccordionDetails>
                        </Accordion> */}

                        {/* <div className="readability-stats">
                                    <p>Reading Time: {readingStats.time}</p>
                                    <p>Speaking Time: {readingStats.words} words</p>
                                </div> */}
                    </div>
                </div>
                {/* a1 */}



                {/* </Grid>



                    <Grid item xs >
                         <Item>xs</Item> 
                        <div >right</div>
                    </Grid>
                </Grid> */}

            </div>
        </>
    );
}

export default Home;

