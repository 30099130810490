export const MenuItems = [
    {
      title: 'Terms & Services',
      path: 'https://onlinewordcounterfree.com/page/blog/',
      cName: 'dropdown-link'
    },
    {
      title: 'help Us Out',
      path: 'https://onlinewordcounterfree.com/page/terms-of-service/',
      cName: 'dropdown-link'
    },
    {
      title: 'Help WordCounter',
      path: 'https://onlinewordcounterfree.com/page/help/',
      cName: 'dropdown-link'
    },
    {
      title: 'DCMA Policy',
      path: 'https://onlinewordcounterfree.com/page/dmca/',
      cName: 'dropdown-link'
    },
    {
      title: 'Terms & Sale',
      path: 'https://onlinewordcounterfree.com/page/terms-of-sale/',
      cName: 'dropdown-link'
    },
    {
      title: 'Privacy Policy',
      path: 'https://onlinewordcounterfree.com/page/privacy-policy/',
      cName: 'dropdown-link'
    },
    {
      title: 'Accessibility',
      path: 'https://onlinewordcounterfree.com/page/accessibility/',
      cName: 'dropdown-link'
    },
    {
      title: 'Subscribe',
      path: 'https://onlinewordcounterfree.com/page/subscribe/',
      cName: 'dropdown-link'
    },
    {
      title: 'Help',
      path: 'https://onlinewordcounterfree.com/page/help/',
      cName: 'dropdown-link'
    },
    {
      title: 'Trust',
      path: 'https://onlinewordcounterfree.com/page/legal-information/',
      cName: 'dropdown-link'
    }
  ];
  