import React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
// import SendIcon from '@mui/icons-material/Send';
import { FaqItem } from '../../editableFiles/FaqItem';
// import { Button } from '@mui/material';
// import Grid from '@mui/material/Grid';
import './Faq.css'



const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.7rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(1),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const FAQ = () => {
    const [expanded, setExpanded] = React.useState(null);

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : null);
    };

    return (
        <>
            <div className='accordianScroll'>
                {FaqItem.map((entry, index) => (
                    <Accordion
                        key={index}
                        expanded={expanded === `panel${index}`}
                        onChange={handleChange(`panel${index}`)}
                        disableGutters="true"
                    >
                        <AccordionSummary aria-controls={`panel${index}d-content`} id={`panel${index}d-header`}>
                            <Typography>{entry.question}?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>{entry.answer}</Typography>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </div>
            {/* <div className='askQuestionsSection'> */}
            {/* <Grid container spacing={1}>
                <Grid item xs={10}>
                    <input placeholder='Ask your question here' type='text' className='askQuestions' ></input>
                </Grid>
                <Grid item xs={2}>
                    <Button variant="contained" endIcon={<SendIcon />} className='buttonSend'>
                        Send
                    </Button>        
                </Grid>

            </Grid> */}

            {/* </div> */}
        </>
    );
}

export default FAQ;
