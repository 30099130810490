import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import Dropdown from './Dropdown.js';
import logo from '../images/logo.jpg'
import ssk from '../images/ssk.png'


function Navbar() {
  const [click, setClick] = useState(false);
  const [dropdown, setDropdown] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const onMouseEnter = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(true);
    
      
    }
  };


  const onMouseLeave = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(false);
    }
  };

  return (
    <>
    <div className='navParent'>
      <div></div>
      <nav className='navbar'>
      
      <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
          <img className='navlogo' src={ssk} alt=''></img>
        </Link>
        <div className='menu-icon' onClick={handleClick}>
          <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
        </div>
        <ul className={click ? 'nav-menu active' : 'nav-menu'}>
          <li className='nav-item'>
            <Link to='/' className='nav-links' onClick={closeMobileMenu}>
              Home
            </Link>
          </li>
          
          <li className='nav-item'>
            <Link
              to='https://onlinewordcounterfree.com/page'
              target='_blank'
              className='nav-links'
              onClick={closeMobileMenu}
            >
              Blog
            </Link>
          </li>
          <li className='nav-item'>
            <Link
              to='https://onlinewordcounterfree.com/page/contact/'
              target='_blank'
              className='nav-links'
              onClick={closeMobileMenu}
            >
              Contact Us
            </Link>
          </li>
          <li
            className='nav-item'
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            // onClick={setTrueDropdown}
          >
            <Link
              to='https://onlinewordcounterfree.com/page/about-us'
              className='nav-links'
              onClick={closeMobileMenu}
            >
              About <i className='fas fa-caret-down' />
            </Link>
            {dropdown && <Dropdown />}
          </li>
          
        </ul>
        

      </nav>
      <div></div>
    </div>
      
        
    </>
  );
}

export default Navbar;
