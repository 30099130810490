import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter } from '@fortawesome/free-brands-svg-icons';



function Footer() {

    return (
        <>
            {/* <div className='footer'>
                <div className='leftFooter'>
                    <Link to='/' className='footerlink' target='_blank'>Home</Link>
                    <Link to='https://onlinewordcounterfree.com/page/blog/' className='footerlink' target='_blank'>Blog</Link>
                    <Link to='https://onlinewordcounterfree.com/page/terms-of-service/' className='footerlink' target='_blank'>Terms & Services</Link>
                    <Link to='https://onlinewordcounterfree.com/page/help/' className='footerlink' target='_blank'>Help WordCounter</Link>
                    <Link to='https://onlinewordcounterfree.com/page/dmca/' className='footerlink' target='_blank'>DCMA Policy</Link>
                    <Link to='https://onlinewordcounterfree.com/page/terms-of-sale/' className='footerlink' target='_blank'>Terms & Sale</Link>
                </div>
                <div className='midFooter'>
                    <Link to='https://onlinewordcounterfree.com/page/authors/' className='footerlink' target='_blank'>Author</Link>
                    <Link to='https://onlinewordcounterfree.com/page/work-with-us/' className='footerlink' target='_blank'>Work with Us</Link>
                    <Link to='https://onlinewordcounterfree.com/page/privacy-policy/' className='footerlink' target='_blank'>Privacy Policy</Link>
                    <Link to='https://onlinewordcounterfree.com/page/accessibility/' className='footerlink' target='_blank'>Accessibility</Link>
                    <Link to='https://onlinewordcounterfree.com/page/frequently-asked-questions/' className='footerlink' target='_blank'>FAQ</Link>
                    <Link to='https://onlinewordcounterfree.com/page/subscribe/' className='footerlink' target='_blank'>Subscribe</Link>
                </div>
                <div className='rightFooter'>
                    <Link to='https://onlinewordcounterfree.com/page/sitemap_index.xml' className='footerlink' target='_blank'>Site Map</Link>
                    <Link to='https://onlinewordcounterfree.com/page/guest-post/' className='footerlink' target='_blank'>Guest Posting</Link>
                    <Link to='https://onlinewordcounterfree.com/page/help/' className='footerlink' target='_blank'>Help</Link>
                    <Link to='https://onlinewordcounterfree.com/page/legal-information/' className='footerlink' target='_blank'>Trust</Link>
                    <Link to='/' className='footerlink' target='_blank'> <FontAwesomeIcon className='iconcolor' icon={faFacebook} /> Facebook </Link>
                    <Link to='/' className='footerlink' target='_blank'> <FontAwesomeIcon className='iconcolor' icon={faTwitter} /> Twitter </Link>
                </div>


            </div> */}
            {/* <div style={{ boxSizing: 'border-box', textAlign: 'left', backgroundColor: 'rgb(0, 0, 0)' , fontSize:'18px'}}>
                <div style={{ boxSizing: 'border-box', width: '95%', height: '288.5px', maxWidth: '1280px', marginRight: 'auto', marginLeft: 'auto', backgroundColor: 'rgb(0, 0, 0)' }}>
                    <div style={{ boxSizing: 'border-box', paddingTop: '40px' }}>
                        <div style={{ boxSizing: 'border-box', display: 'flex', flexWrap: 'wrap' }}>
                            <div style={{ boxSizing: 'border-box', width: '20%' }}>
                                <div style={{ boxSizing: 'border-box' }}>

                                    <figure style={{ margin: '0px', boxSizing: 'border-box', padding: '0px', border: '0px none rgb(34, 34, 34)', textAlign: 'center' }}>
                                        <a href="https://onlinewordcounterfree.com/" style={{ textDecoration: 'none solid rgb(12, 71, 103)', transition: 'color 0.1s ease-in-out 0s, background-color 0.1s ease-in-out 0s', boxSizing: 'border-box', color: 'rgb(12, 71, 103)' }}>
                                            <img loading="lazy" decoding="async" width="292" height="147" src="https://onlinewordcounterfree.com/page/wp-content/uploads/2024/01/cropped-new-logo-2.png" alt="" title="cropped-new-logo" style={{ height: 'auto', maxWidth: '100%', boxSizing: 'border-box', width: '200px', verticalAlign: 'middle' }} />
                                        </a>
                                    </figure>
                                </div>
                            </div>
                            <div style={{ boxSizing: 'border-box', width: '80%' }}>
                                <div style={{ boxSizing: 'border-box' }}>

                                    <p style={{ marginBottom: '25.5px', boxSizing: 'border-box', padding: '0px', border: '0px none rgb(255, 255, 255)', color: 'rgb(255, 255, 255)' }}>Welcome to Online Word Counter Free! By using our website, you agree to these terms. Our goal is to make word counting easy for you, and these terms ensure a positive experience for everyone. If you disagree, please refrain from using our services. Welcome to Online Word Counter Free! By using our website, you agree to these terms. Our goal is to make word counting easy for you, and these terms ensure a positive experience for everyone. If you disagree, please refrain from using our services.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ boxSizing: 'border-box' }}>
                        <div style={{ boxSizing: 'border-box', textAlign: 'center' }}>

                            <a href="https://onlinewordcounterfree.com/page/authors/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none solid rgb(255, 255, 255)', transition: 'color 0.1s ease-in-out 0s, background-color 0.1s ease-in-out 0s', boxSizing: 'border-box', display: 'inline-flex', padding: '15px 20px', borderRadius: '15px', color: 'rgb(255, 255, 255)' }}><strong style={{ fontWeight: '700', boxSizing: 'border-box' }}>Authors</strong></a>
                            <a href="https://onlinewordcounterfree.com/page/work-with-us/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none solid rgb(255, 255, 255)', transition: 'color 0.1s ease-in-out 0s, background-color 0.1s ease-in-out 0s', boxSizing: 'border-box', display: 'inline-flex', padding: '15px 20px', borderRadius: '15px', color: 'rgb(255, 255, 255)' }}><strong style={{ fontWeight: '700', boxSizing: 'border-box' }}>Work With us</strong></a>
                            <a href="https://onlinewordcounterfree.com/page/advertise/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none solid rgb(255, 255, 255)', transition: 'color 0.1s ease-in-out 0s, background-color 0.1s ease-in-out 0s', boxSizing: 'border-box', display: 'inline-flex', padding: '15px 20px', borderRadius: '15px', color: 'rgb(255, 255, 255)' }}><strong style={{ fontWeight: '700', boxSizing: 'border-box' }}>Advertise</strong></a>
                            <a href="https://onlinewordcounterfree.com/page/guest-post/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none solid rgb(255, 255, 255)', transition: 'color 0.1s ease-in-out 0s, background-color 0.1s ease-in-out 0s', boxSizing: 'border-box', display: 'inline-flex', padding: '15px 20px', borderRadius: '15px', color: 'rgb(255, 255, 255)' }}><strong style={{ fontWeight: '700', boxSizing: 'border-box' }}>Guest Posting</strong></a>
                            <a href="https://onlinewordcounterfree.com/page/frequently-asked-questions/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none solid rgb(255, 255, 255)', transition: 'color 0.1s ease-in-out 0s, background-color 0.1s ease-in-out 0s', boxSizing: 'border-box', display: 'inline-flex', padding: '15px 20px', borderRadius: '15px', color: 'rgb(255, 255, 255)' }}><strong style={{ fontWeight: '700', boxSizing: 'border-box' }}>FAQ</strong></a>
                            <a href="https://onlinewordcounterfree.com/page/sitemap_index.xml" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none solid rgb(255, 255, 255)', transition: 'color 0.1s ease-in-out 0s, background-color 0.1s ease-in-out 0s', boxSizing: 'border-box', display: 'inline-flex', padding: '15px 20px', borderRadius: '15px', color: 'rgb(255, 255, 255)' }}><strong style={{ fontWeight: '700', boxSizing: 'border-box' }}>Site Map</strong></a>
                        </div>
                    </div>
                    <div style={{ boxSizing: 'border-box', paddingTop: '10px' }}>
                        <div style={{ boxSizing: 'border-box' }}>

                            <p style={{ marginBottom: '0px', boxSizing: 'border-box', padding: '0px 0px 30px', border: '0px none rgb(255, 255, 255)', textAlign: 'center', paddingBottom: '30px', color: 'rgb(255, 255, 255)' }}>© 2024 by <strong style={{ fontWeight: '700', boxSizing: 'border-box' }}>Online Word Counter Free</strong>: All rights reserved</p>
                        </div>
                    </div>
                </div>
            </div> */}

            <div class="container-xdi">
  <div class="container-2yl">
    <div class="container-mwp">
      <div class="wrapper-v41">
        <div class="column-6vc column-thz">
          <div>
            <figure class="block-oik block-pza">
              <a href="https://onlinewordcounterfree.com/">
                <img width="292" height="147" class="image-j5q" src="https://onlinewordcounterfree.com/page/wp-content/uploads/2024/01/cropped-new-logo-2.png"/>
              </a>
            </figure>
          </div>
        </div>
        <div class="column-6vc column-npo">
          <div>
            <p class="gb-headline-vv1">
              Welcome to Online Word Counter Free! By using our website, you agree to these terms. Our goal is to make word counting easy for you, and these terms ensure a positive experience for everyone. If you disagree, please refrain from using our services. Welcome to Online Word Counter Free! By using our website, you agree to these terms. Our goal is to make word counting easy for you, and these terms ensure a positive experience for everyone. If you disagree, please refrain from using our services.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="container-78e">
        <a class="button-92g button-flc" target='_blank' href="https://onlinewordcounterfree.com/page/authors/">
          <strong>
            Authors
          </strong>
        </a>
        <a class="button-92g button-v6k" target='_blank' href="https://onlinewordcounterfree.com/page/work-with-us/">
          <strong>
            Work With us
          </strong>
        </a>
        <a class="button-92g button-5en" target='_blank' href="https://onlinewordcounterfree.com/page/advertise/">
          <strong>
            Advertise
          </strong>
        </a>
        <a class="button-92g button-dkm" target='_blank' href="https://onlinewordcounterfree.com/page/guest-post/">
          <strong>
            Guest Posting
          </strong>
        </a>
        <a class="button-92g button-qtz" target='_blank' href="https://onlinewordcounterfree.com/page/frequently-asked-questions/">
          <strong>
            FAQ
          </strong>
        </a>
        <a class="button-92g button-g3m" target='_blank' href="https://onlinewordcounterfree.com/page/sitemap_index.xml">
          <strong>
            Site Map
          </strong>
        </a>
      </div>
    </div>
    <div class="container-njm">
      <div>
        <p class="gb-headline-eee">
          © 2024 by 
          <strong>
            Online Word Counter Free
          </strong>
          : All rights reserved
        </p>
      </div>
    </div>
  </div>
</div>
        </>
    );
}

export default Footer;
